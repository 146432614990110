import getData from "@/__main__/get-data.mjs";
import ValorantPlayerAgentStats from "@/data-models/valorant-player-agent-stats.mjs";
import ValorantPlayerMatchWinHistoryModel from "@/data-models/valorant-player-match-win-history.mjs";
import ValorantPlayerStats from "@/data-models/valorant-player-stats.mjs";
import * as API from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import fetchProfile, {
  getAuthorizationHeaders,
} from "@/game-val/valorant-fetch-profile.mjs";
import { devLog } from "@/util/dev.mjs";

export default async function fetchData(pathVariables, urlSearchParams, state) {
  const [content] = await fetchConstants();
  const headers = await getAuthorizationHeaders();
  const platformPath = getPlatformPath(false); // PC only

  const promises = [];

  const latestActId = content.acts?.find((act) => act.endedAt === null)?.uuid;

  const teamProfiles =
    state.players?.map(({ gameName, tagLine, isMe }) =>
      fetchProfile(gameName, tagLine)
        .then((profile) => {
          return Promise.all([
            getData(
              API.getPlayerStats({
                puuid: profile.puuid,
                actUuid: latestActId,
                region: profile.valorantProfile.region,
              }),
              ValorantPlayerStats,
              [
                "val",
                "playerActStats",
                profile.puuid,
                latestActId,
                platformPath,
              ],
              { headers },
            ),
            getData(
              API.getMatchWinHistory({
                puuid: profile.puuid,
                actUuid: latestActId,
                queue: state.queue,
                offset: 0,
                first: 5,
              }),
              ValorantPlayerMatchWinHistoryModel,
              [
                "val",
                "playerMatchWinHistory",
                profile.puuid,
                latestActId,
                state.queue,
              ],
              { headers },
            ),
            ...(isMe
              ? [
                  getData(
                    API.getAgentStats({
                      puuid: profile.puuid,
                      actUuid: latestActId,
                      region: profile.valorantProfile.region,
                      queue: state.queue,
                    }),
                    ValorantPlayerAgentStats,
                    [
                      "val",
                      "playerAgentStats",
                      profile.puuid,
                      latestActId,
                      platformPath,
                      state.queue,
                    ],
                    { headers },
                  ),
                ]
              : []),
          ]);
        })
        .catch(devLog),
    ) ?? [];

  promises.push(...teamProfiles);

  return Promise.all(promises);
}
